<template>
  <div class='news-wrap' v-if="commentsData.length">
    <div class='news-wrap-head'>
      <span class='s1'></span>
      <span class='s2'>热门评论</span>
    </div>
    <div class='news-wrap-main' @click='showOpenSelectDialog(noteInfo, momentDetail)'>
      <div class='commentNo' v-if='!commentsData.length'>暂无评论</div>
      <div class='comment-wrap' v-else v-for='item in commentsData' :key='item.id'>
        <div class='cw-left' @click='athorOpenSelectDialog(item, author)'>
          <img v-if='item.userInfo.avatar' :src='item.userInfo.avatar' tag='img' />
          <img v-else src='https://cdn.9kd.com/kdnet/a6593d8447984c8a9fc798ad5c327a1d.png' tag='img' />
        </div>
        <div class='cw-right'>
          <div class='p1'>
            <p class='s1' tag='p' @click='athorOpenSelectDialog(item, author)'> {{ item.userInfo.nickname }}</p>
            <p class='s2'>
              <span v-if='item.praise'>{{ item.praise }}</span>
              <i class='iconfont ico' tag='i' @click='athorOpenSelectDialog(noteInfo, praise)'>&#xe696;</i>
            </p>
          </div>
          <p class='p2'>{{ item.content }}</p>
          <p class='p3'>
            <span class='hot' v-if='item.hot'>热评<i class='iconfont ico'>&#xe69e;</i></span>
            <span class='s1'>{{ item.timeLong | compareDate }}</span>
            <span class='s1'>{{ item.ipLocation }}</span>
            <span class='s2' tag='span' @click='athorOpenSelectDialog(noteInfo, reply)'>回复{{ item.replies ? item.replies : '' }}</span>
          </p>
          <p class='quote' v-if='item.parentContent'>
            <span class='s1'>{{ item.parentUserInfo.nickname }}：</span><span class='s2'>{{ item.parentContent }}</span>
          </p>
        </div>
      </div>
    </div>
    <div v-if='commentsData.length > 3' class='commentNo' @click='athorOpenSelectDialog(noteInfo, lookcomment)'>查看全部评论<i class='iconfont'>&#xe65d;</i></div>
  </div>
</template>

<script>
import Bus from '../utils/bus';
export default {
  props: {
    commentsData:{
      type: Array,
      default: () => []
    },
    noteInfo:{
      type: Object,
      default: () => {
        return {
          author: {}
        }
      }
    },
    commentType: {
      type: String,
      default: 'moment'
    }
  },
  data() {
    return {
      praise: Bus.CONTINUTETYPE_PRAISE,
      lookcomment: Bus.CONTINUTETYPE_LOOK_COMMENT,
      reply: Bus.CONTINUTETYPE_REPLY,
      author: Bus.CONTINUTETYPE_AUTHOR,
      momentDetail: Bus.MOMENT_DETAIL,
    }
  },
  methods:{
    showOpenSelectDialog(noteInfo, type) {
      if(this.commentType == 'moment' && type == this.momentDetail) {
        this.$emit('show-open-select-dialog', noteInfo, type)
      }
    },
    /**
     * 用于文章打开
     */
    athorOpenSelectDialog(noteInfo, type) {
      if (this.commentType != 'moment') {
        this.$emit('show-open-select-dialog', noteInfo, type)
      }
      
    }
  }
}
</script>

<style lang="less" scoped>
  .news-wrap {
    box-sizing: border-box;
    padding: 20px 15px;
    font-family: PingFang SC, PingFang SC-Regular;
    border-top: 10px solid #F5F5F4;
    .news-wrap-head {
      display: flex;
      align-items: center;

      .s1 {
        width: 3px;
        height: 18px;
        background: #F7321C;
        margin-right: 8px;
      }

      .s2 {
        font-weight: 600;
        font-size: 16px;
        color: #3a3e45;
        letter-spacing: 1px;
      }

      .comment {
        margin-left: 220px;
        font-size: 14px;
      }
    }

    .comment-wrap {
      display: flex;
      margin-top: 20px;

      .cw-left {
        width: 32px;
        height: 32px;
        margin-right: 14px;

        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
      }

      .cw-right {
        width: 292px;
        text-align: left;
        font-size: 15px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #3a3e45;

        .p1 {
          &:after {
            display: block;
            content: '';
            clear: both;
          }

          .f1 {
            color: #909AA8;
            font-size: 13px;
          }

          .i1 {
            width: 14px;
            height: 14px;
            vertical-align: middle;
          }

          .s1 {
            float: left;
          }

          .s2 {
            color: #909aa8;
            float: right;
            font-size: 13px;

            .ico {
              margin-left: 5px;
              color: #909aa8;
              font-size: 14px;
            }
          }
        }

        .p2 {
          margin: 12px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        .p3 {
          font-size: 12px;

          .hot {
            color: #F7321C;
            margin-right: 10px;

            .ico {
              margin-left: 3px;
            }
          }

          .s1 {
            color: #afb5bf;
            margin-right: 14px;
          }

          .s2 {
            color: #2f333c;
          }
        }

        .quote {
          width: 100%;
          min-height: 43px;
          padding: 12px;
          box-sizing: border-box;
          background: #f3f7fa;
          border-radius: 4px;
          margin-top: 12px;

          .s1 {
            font-size: 13px;
            color: #2f333c;
          }

          .s2 {
            font-size: 13px;
            color: #2f333c;
          }
        }
      }
    }

    .commentNo {
      margin-top: 20px;
      text-align: center;
      font-size: 14px;
      color: #afb5bf;
    }

    .recommend-wrap {
      display: flex;
      justify-content: space-between;
      font-family: PingFang SC, PingFang SC-Regular;
      padding: 14px 0;
      border-bottom: 1px solid #f6f6f6;

      .rw-left {
        text-align: left;
        width: 78%;
        display: flex;
        flex-direction: column;

        .p1 {
          font-size: 17px;
          font-family: Source Han Sans, Source Han Sans-Regular;
          font-weight: 400;
          text-align: left;
          color: #111111;
          line-height: 24px;
          letter-spacing: 1px;
        }

        .text-ellipsis {
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .p-holder {
          flex: 1;
        }

        .p2 {
          font-size: 12px;
          color: #afb5bf;
          margin-top: 5px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .s1 {
            margin-right: 5px;

            &.max-len {
              flex-shrink: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .s2 {
            margin-right: 5px;
            flex-shrink: 0;
          }

          .open-app-label {
            width: 77px;
            height: 21px;
            margin-right: 8px;
          }

        }

        .adv-tip {
          display: flex;
          align-items: center;
          height: 16px;
          padding: 0px 3px 0 3px;
          border: 1px solid #e4e4e4;
          border-radius: 2px;
          font-size: 11px;
        }
      }

      .rw-right {
        flex-shrink: 0;
        width: 114px;
        height: 78px;
        overflow: hidden;
        border-radius: 4px;

        .article-img, .adv-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .width-100 {
        width: 100%;
      }
    }
  }
</style>